import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import "./TwoFactorAuth.styles.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal, CEXModal } from "@careexpand/cex-core-components/esm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { generate2FAThunkAction, verify2FAThunkAction } from "../../redux/auth/auth.slice";

import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { faInputNumeric } from "@fortawesome/pro-light-svg-icons";

const TwoFactorAuth = () => {
    const [t] = useTranslation("global");
    const [email, setEmail] = useState<string>("");
    const [otp, setOTP] = useState<string>("");
    const [error, setError] = useState<any>({});
    const [, setIsLoading] = useState(false);
    const [modalAction, setModalAction] = useState<string | null>(null);
    const [timeLeft, setTimeLeft] = useState(30);
    const dispatch = useDispatch<AppDispatch>();
    const [TwoFAError, setTwoFAError] = useState<any>();
    const [TwoMessage, setTwoMessage] = useState<any>();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    setIsButtonDisabled(false);
                    setTwoMessage(null);
                    return 0;
                }
                setTwoMessage(`Please wait ${prevTime - 1} seconds.`);
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const sendOTP = (email: string) => {
        setEmail(email);
        setIsButtonDisabled(true);
        const url = process.env.REACT_APP_API_URL + "/request2FAOTP";
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
        }).then((res: any) => {
            if (!res.ok) {
                setModalAction("FAILED_EMAIL");
                setIsButtonDisabled(false);
                return;
            } else {
                setTimeLeft(30);
                setModalAction("ENTER_OTP");
                setTwoMessage(`Code resent successfully. Please wait ${timeLeft} seconds.`);
            }
            setIsLoading(false);
        });
    };

    const handleOTPVerification = () => {
        if (!otp || otp.length < 6) {
            setError({ otp: "Enter valid OTP" });
            return;
        }

        const url = process.env.REACT_APP_API_URL + "/verify2FAOTP";
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, otp }),
        })
            .then((res: any) => {
                if (!res.ok) return Promise.reject(res);
                res.json().then((resData: any) => {
                    const token = resData.token;
                    localStorage.setItem("Token", token);
                });
            })
            .catch(() => {
                setError({ otp: "Invalid OTP or failed to validate OTP, try again" });
            });
    };

    const renderPopupActions = () => {
        let modalType = "info";
        let title = "Info";
        let subtitle = "Info";

        switch (modalAction) {
            case "INVALID_DATA":
                modalType = "error";
                title = "Invalid data";
                subtitle = "Please check the information entered";
                break;
            case "FAILED_EMAIL":
                modalType = "error";
                title = "Failed";
                subtitle = "Failed to send OTP, try again later";
                break;
            case "EMAIL_SENT":
                modalType = "info";
                title = "OTP sent successfully";
                subtitle = "Please check your inbox to retrieve the OTP";
                break;
        }

        return <CEXModal type={modalType} handleModal={() => setModalAction(null)} title={title} subtitle={subtitle} />;
    };

    const renderModal = () => {
        return (
            <Modal
                title={"Please Enter OTP"}
                titleDescription={"Enter the OTP sent to your email"}
                closeModal={() => {
                    setModalAction(null);
                }}
                buttons={[
                    {
                        onClick: handleOTPVerification,
                        displayText: "Verify OTP",
                        theme: { primaryColor: "#009EFC", textColor: "#FFF" },
                    },
                ]}
            >
                <div>
                    <div className="otp__input">
                        <input type="text" value={otp} onChange={e => setOTP(e.target.value)} />
                        {error.otp && <label className="input__error">{error.otp}</label>}
                    </div>
                    {timeLeft > 0 ? (
                        <div className="cex-modal__subtitle">
                            Time left:{" "}
                            {`${Math.floor(timeLeft / 60)}:${Number(timeLeft % 60)
                                .toString()
                                .padStart(2, "0")}`}{" "}
                            seconds
                        </div>
                    ) : (
                        <div className="cex-modal__subtitle">
                            <span
                                style={{ cursor: "pointer", color: "#489dff", fontSize: "15px" }}
                                onClick={() => sendOTP(email)}
                            >
                                Resend OTP
                            </span>
                        </div>
                    )}
                </div>
            </Modal>
        );
    };

    const handleManageClick = () => {
        setIsButtonDisabled(true);
        setTimeLeft(30);
        setTwoMessage(`Code resent successfully. Please wait ${30} seconds.`);

        const timer = setInterval(() => {
            setTimeLeft(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    setIsButtonDisabled(false);
                    setTwoMessage(null);
                    return 0;
                }
                setTwoMessage(`Code resent successfully. Please wait ${prevTime - 1} seconds.`);
                return prevTime - 1;
            });
        }, 1000);

        dispatch(generate2FAThunkAction({ data: {} })).then(() => {
            setTwoFAError("");
        });
    };

    return (
        <div className="twoFactorAuthForm">
            {modalAction && modalAction !== "ENTER_OTP" && renderPopupActions()}
            {modalAction === "ENTER_OTP" && renderModal()}
            <Formik
                validateOnChange
                validateOnBlur
                initialValues={{ email: "" }}
                onSubmit={() => {
                    ("");
                }}
                validationSchema={yup.object().shape({
                    code:
                        //6 digits number
                        yup
                            .string()
                            .max(6)
                            .required("Code is required")
                            .matches(/^[0-9]{6}$/, { message: "Code must be 6 digits" }),
                })}
            >
                {({ errors, touched, submitCount, values, validateForm, setFieldValue }: any) => (
                    <Form className="userDetailsForm">
                        <div className="inputFieldDivRegister">
                            <div className="nameLastNameContainer">
                                <div className="pi-input-skel-block">
                                    <div className="pi-input" style={{ width: 290 }}>
                                        <label className="pi-label" htmlFor="">
                                            {t("forgot-password.code")}
                                        </label>
                                        <div className="pi-input-skel">
                                            <span className="pi-input-prefix" style={{ padding: "8px 8px" }}>
                                                <FontAwesomeIcon
                                                    icon={faInputNumeric}
                                                    size="2xl"
                                                    style={{ width: "17px" }}
                                                />
                                            </span>
                                            <Field
                                                name="code"
                                                type="number"
                                                className="pi-input-ele"
                                                placeholder={t("forgot-password.code-placeholder")}
                                                maxLength={6}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    if (e.target.value.length > 6 || !/^\d*$/.test(e.target.value)) {
                                                        return;
                                                    }
                                                    setFieldValue("code", e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <label className="input__error">
                                        {errors.code && (touched.phone || submitCount > 0) && errors.code}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div>
                                <button
                                    type="button"
                                    className="forgot-commonText"
                                    style={{
                                        float: "left",

                                        background: "trasparent",
                                        border: "none",
                                        marginTop: "13px",
                                        marginLeft: "-5px",
                                        opacity: isButtonDisabled ? 0.5 : 1,
                                        cursor: isButtonDisabled ? "not-allowed" : "pointer",
                                    }}
                                    onClick={handleManageClick}
                                    disabled={isButtonDisabled}
                                >
                                    {isButtonDisabled ? `Resend Code` : "Resend Code"}
                                </button>
                            </div>
                            <div>
                                {TwoFAError && TwoMessage.length === 0 ? (
                                    <label className="input__error">{TwoFAError}</label>
                                ) : (
                                    <label className="input__error" style={{ color: "green" }}>
                                        {TwoMessage}
                                    </label>
                                )}
                            </div>
                        </div>
                        <div className="link-button-container">
                            <Link to="/login" className="forgot-goToRegisterLink" style={{ textDecoration: "none" }}>
                                {t("forgot-password.login-link")}
                            </Link>
                            <div>
                                <button
                                    type="submit"
                                    className="BlueButton"
                                    onClick={() => {
                                        validateForm();
                                        if (values.code && Object.keys(errors).length === 0) {
                                            setIsLoading(true);
                                            dispatch(
                                                verify2FAThunkAction({
                                                    data: {
                                                        code: values.code,
                                                    },
                                                })
                                            ).then((res: any) => {
                                                if (res?.payload?.result?.success || res?.payload?.success) {
                                                    setIsLoading(false);
                                                    localStorage.setItem("Token", res?.payload?.jwtToken);
                                                    window.location.assign("/home#/home");
                                                } else {
                                                    setIsLoading(false);
                                                    //alert("Invalid OTP");
                                                    setTwoFAError(
                                                        "The code you entered is invalid or has expired. Please try again."
                                                    );
                                                    setTwoMessage("");
                                                }
                                                // resetForm();
                                                //  setModalAction("EMAIL_SENT");
                                            });
                                        }
                                    }}
                                    style={{
                                        fontSize:
                                            window.innerWidth <= 320
                                                ? "11px"
                                                : window.innerWidth === 768
                                                ? "10px"
                                                : window.innerWidth >= 426 && window.innerWidth <= 514
                                                ? "10px"
                                                : "14px",
                                    }}
                                >
                                    {"Verify code"}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default TwoFactorAuth;
